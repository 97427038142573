import React, { Component } from 'react';
import './App.css';
import { Container, Button, Modal, ListGroup, Row, Col, InputGroup, SplitButton, Dropdown, Form } from 'react-bootstrap';
import  DatePicker  from 'react-datepicker';
import { addDays, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const serverUrl = "https://www.evergreen-restaurant.us/server";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      sectionReady: false,
      addShow: false,
      curSection: '1',
      tableOption: 'Choose a Table',
      peopleEnter: '',
      showDropList: false,
      selectedSection: '',
      selectedId: '',
      showEditModal: false,
      editShow: false,
      peopleEditEnter: '',
      tableTime: '',
      tableStatus: '',
      selectTableEditId: '',
      selectedTableEdit: '',
      showActivateTableOnly: false,
      showNewReservationModal: false,
      showWaitlist: false,
      reservationName: '',
      reservationSize: '',
      reservationPhone: '',
      reservationNote: '',
      startDate: new Date(),
      selectedData: new Date(),
      waitlistSelectedData: new Date(),
      reservationList: [],
      waitList: [],
      showEditReservationModal: false, 
      editReservationName: '',
      editReservationSize: '',
      editReservationPhone: '',
      editReservationNote: '',
      editSelectedData: new Date(),
      showReservationStatusDropList: false,
      editReservationStatus: '',
      editReservationId: '',
      showEditWaitlistModal: false,
      editWaitlistName: '',
      editWaitlistSize: '',
      editWaitlistPhone: '',
      editWaitlistNote: '',
      editWaitlistSelectedData: new Date(),
      editWaitlistStatus: '',
      showWaitlistStatusDropList: false,
    };
  }

  componentDidMount(){
    let section = localStorage.getItem('section');

    if(section == null){
      this.setState({show: true});
    }else{
      this.setState({sectionReady: true});
    }


    this.setState({waitList: JSON.parse(localStorage.getItem('waitlist'))});

    this.loadReservation();

    this.interval = setInterval(() => this.loadReservation(), 60000);

  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleModal = ()=>{
    this.setState({
      show: this.state.show?false:true
    })
  }


  setSection = (count)=>{
    localStorage.setItem('section', count);
    this.handleModal();

    this.generateData()

    this.setState({sectionReady: true});

  }

  generateData = ()=>{
    let sectionCount = localStorage.getItem('section');

    console.log('There are ' + sectionCount + ' sections!');

    for(let i =1; i<=sectionCount; i++){
      localStorage.setItem(i, JSON.stringify({'name':'','data':[]}));
    }

  }

  addSection = ()=>{

    let tmpSection = prompt(`How many sections to add?`);

    if(tmpSection == null)
      return;

    let newSection = tmpSection - 0;

    if(!Number.isInteger(newSection) || newSection <= 0){
      alert("Please enter a valid section number!");
      return;
    }


    let sectionCount = localStorage.getItem('section')-0;

    localStorage.setItem('section', sectionCount + newSection);

    console.log('Add ' + newSection + ' sections!');

    for(let i =newSection; i>0; --i){
      localStorage.setItem(sectionCount+i, JSON.stringify({'name':'','data':[]}));
    }

    this.setState({sectionReady: true});
  }

  setName = (section) =>{
    let person = prompt(`Please enter a name for section ${section}.`);

    if(person == null)
      return;

    this.setState({sectionReady:false});

    let tmpSectionData = JSON.parse(localStorage.getItem(section));

    let newSectionData = {'name': person, 'data': tmpSectionData.data};

    localStorage.setItem(section, JSON.stringify(newSectionData));

    //console.log(localStorage.getItem(section).name);
    this.setState({sectionReady:true});
  }

  handleAddModel = (section)=>{
    this.setState({
      addShow: this.state.addShow?false:true,
      curSection: section,
      tableOption: 'Choose a Table',
      peopleEnter: '',
      showDropList: false,
    });
  }

  handleEditModel = (section)=>{
    this.setState({
      editShow: this.state.editShow?false:true,
      curSection: section,
      peopleEditEnter: '',
      tableTime: '',
      tableStatus: '',
      selectTableEditId: '',
      selectedTableEdit: '',
    });
  }

  addRecord = ()=>{

    if(this.state.curSection == null){
      alert("Something went wrong, please refresh page and try again!");
      return;
    }

    if(this.state.tableOption == 'Choose a Table'){
      alert("Please choose a table!");
      return;
    }

    let peopleNumber = this.state.peopleEnter-0;

    if(!Number.isInteger(peopleNumber) || peopleNumber <= 0){
      alert("Please enter a valid people number!");
      return;
    }

    this.setState({sectionReady:false});

    let tmpSectionData = JSON.parse(localStorage.getItem(this.state.curSection));

    let tmpSectionGroup = tmpSectionData.data.length>0?JSON.parse(tmpSectionData.data):tmpSectionData.data;

    tmpSectionGroup.push({'id':tmpSectionGroup.length, 'count':peopleNumber, 'table': this.state.tableOption, statu: 'active', 'time':new Date().toLocaleTimeString() });

    let newSectionData = {'name': tmpSectionData.name, 'data': JSON.stringify(tmpSectionGroup)};

    localStorage.setItem(this.state.curSection, JSON.stringify(newSectionData));

    //console.log(localStorage.getItem(this.state.curSection).name);
    this.setState({
      sectionReady:true
    });

    this.handleAddModel('');
  }

  editRecord = (data, singleData)=>{
    //data.section, singleData.id
    console.log(data);
    console.log(singleData);

    this.setState({
      selectedSection: data.section,
      selectedId: singleData.id,
      editShow: this.state.editShow?false:true,
      peopleEditEnter: singleData.count,
      selectedTableEdit: singleData.table,
      tableTime: singleData.time,
      tableStatus: singleData.statu,
      selectTableEditId: singleData.id,
    })
  }


  editRecordAction = ()=>{
      let record = JSON.parse(localStorage.getItem(this.state.selectedSection));

      let JsonData = JSON.parse(record.data);

      for(let i=0; i<JsonData.length; i++){
        if(JsonData[i].id == this.state.selectedId){
          JsonData[i].table = this.state.selectedTableEdit;
          JsonData[i].statu = this.state.tableStatus;
          JsonData[i].count = this.state.peopleEditEnter;
          break;
        }
      }

      record.data = JSON.stringify(JsonData);

      localStorage.setItem(this.state.selectedSection, JSON.stringify(record));
      this.setState({sectionReady:true});

      this.setState({
        selectedSection: '',
        selectedId: '',
        editShow: this.state.editShow?false:true,
        peopleEditEnter: '',
        selectedTableEdit: '',
        tableTime: '',
        tableStatus: '',
        selectTableEditId: '',
      })
  }


  messageWaitlist = async(time, name, phone, size)=>{

    let localWaitlist = await localStorage.getItem('waitlist');

    let tmpData = [];

    let JSONWaitlist = JSON.parse(localWaitlist);

    for(let i=0; i<JSONWaitlist.length; i++){
      let currentJSONWaitlist = JSON.parse(JSONWaitlist[i]);

      let waitListJson = currentJSONWaitlist;

      if(new Date(currentJSONWaitlist.waitlistSelectedData).getTime() == new Date(time).getTime()){
        waitListJson.status = "noticed"
        waitListJson.waitlistNote = waitListJson.waitlistNote +"- Message noticed at "+format(new Date(), "MM/dd/yyyy, hh:mm aa");
        tmpData.push(JSON.stringify(waitListJson));
      }else{
        tmpData.push(JSONWaitlist[i]);
      }
    }
     await localStorage.setItem('waitlist', JSON.stringify(tmpData));

    let dataJson = {
      id: time,
      name: name,
      phone: phone,
      size: size,
    }

    if(!window.confirm('Notice customer table is ready?'))
      return;

    fetch(`${serverUrl}/orders/reservationnotice`,{
        method: 'POST',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataJson)   
        })

    .then((response) => response.json())
    .then((res) => {
      //this.toastNotify('here');
      if(res.success === true){
        alert("Message successfully sent to the customer!");

        this.setState({showWaitlist: true})
         
      }else{

      }

    })
    .catch((err)=>{
      //console.log(err);
    })

    
  }

  messageReservation = (id, name, phone, size)=>{
    let dataJson = {
      id: id,
      name: name,
      phone: phone,
      size: size,
    }

    if(!window.confirm('Notice customer table is ready?'))
      return;

    fetch(`${serverUrl}/orders/reservationnotice`,{
        method: 'POST',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataJson)   
        })

    .then((response) => response.json())
    .then((res) => {
      //this.toastNotify('here');
      if(res.success === true){
        alert("Message successfully sent to the customer!");
        this.loadReservation();
      }else{
        this.loadReservation();

      }

    })
    .catch((err)=>{
      //console.log(err);
    })




    
  }

  completeAllActive = ()=>{
    if(!window.confirm('Do you want to mark all active tables as complete?'))
      return;

    this.setState({ sectionReady:false });

    let totalSection = parseInt(localStorage.getItem('section'));

    for(let i = 1; i<=totalSection; i++){
      let record = JSON.parse(localStorage.getItem(i+''));

      if(record.data.length == 0)
        continue;

      let JsonData = JSON.parse(record.data);

      for(let i=0; i<JsonData.length; i++){
        if(JsonData[i].statu != 'void')
          JsonData[i].statu = 'complete';
      }

      record.data = JSON.stringify(JsonData);

      localStorage.setItem(i+'', JSON.stringify(record));
    }

    this.setState({ sectionReady:true });
  }

  generateSectionData = ()=>{
    let sectionCount = localStorage.getItem('section');

    let dataSet = [];

    for(let i =1; i<=sectionCount; i++){
      let tmpData = JSON.parse(localStorage.getItem(i));
      dataSet.push({'section':i, 'name': tmpData.name, 'data': tmpData.data});
    }

    return dataSet.map((data) =>{

      let curData = data.data.length>0?JSON.parse(data.data):data.data;

      let totalA =0;
      let totalC =0;

      for(let j=0; j<curData.length; j++){
        if(curData[j].statu == 'active')
          totalA += parseInt(curData[j].count);

        if(curData[j].statu == 'complete')
          totalC += parseInt(curData[j].count);
      }

      return(
        <Col key = {data.section}>
          <ListGroup>
          <ListGroup.Item>
            <Row><Col onClick={()=>this.setName(data.section)}>Section {data.section}({data.name == (undefined||"")?'Click to Set Name':data.name})</Col> <Col xs={'auto'} onClick = {()=>{this.handleAddModel(data.section)}}>➕</Col></Row>
          </ListGroup.Item>
          <ListGroup.Item style={{paddingTop: 0, paddingBottom: 0}}>
            <Row><Col xs={6} style={{backgroundColor:'#b3e6b3'}}>C: {totalC}</Col><Col xs={6}>A: {totalA}</Col></Row>
          </ListGroup.Item>
          {curData.map((singleData) => {
            if(this.state.showActivateTableOnly){
              if(singleData.statu == 'active'){
                return <ListGroup.Item key={singleData.id} style={{backgroundColor:singleData.statu == 'void'?'gray':singleData.statu == 'complete'?'#b3e6b3':'white'}} onClick={()=>{this.editRecord(data, singleData)}}>
                          {singleData.time}<br/>{singleData.table}{': '}{singleData.count} People
                        </ListGroup.Item>;
              }
            }else{
              return <ListGroup.Item key={singleData.id} style={{backgroundColor:singleData.statu == 'void'?'gray':singleData.statu == 'complete'?'#b3e6b3':'white'}} onClick={()=>{this.editRecord(data, singleData)}}>
                      {singleData.time}<br/>{singleData.table}{': '}{singleData.count} People
                    </ListGroup.Item>;
            }
          })}
          </ListGroup>
        </Col>
      );
    });

  }

  resetApp = ()=>{
    if(window.confirm('All data will be deleted! Continue?')){
      localStorage.removeItem('section');
      localStorage.removeItem('waitlist');
      this.handleModal();
    }

  }

  handleNewAppointmentModel = ()=>{
    this.setState({
      showNewReservationModal: this.state.showNewReservationModal?false:true,
      reservationName: '',
      reservationSize: '',
      reservationPhone: '',
      reservationNote:'',
      selectedData: new Date(),
    })
  }

  handleNewWaitlistModel = ()=>{
    this.setState({
      showNewWaitlistModal: this.state.showNewWaitlistModal?false:true,
      waitlistName: '',
      waitlistSize: '',
      waitlistPhone: '',
      waitlistNote:'',
      waitlistSelectedData: new Date(),
    })
  }

  loadReservation = ()=>{

    fetch(`${serverUrl}/orders/reservation`,{
        method: 'get',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }, 
        })

    .then((response) => response.json())
    .then((res) => {
      //this.toastNotify('here');
      if(res.success === true){
        //console.log(res.result);
        this.setState({reservationList: res.result});
      }else{
        //console.log(res.message);
      }

    })
    .catch((err)=>{
      //console.log(err);
    })

  }


  handleNewAppointmentAction = ()=>{

    if(this.state.reservationName.trim() == '' || this.state.reservationSize.trim() == '' || this.state.reservationPhone.trim() == ''){
      alert('Please enter all required reservation information!')
      return;
    }

    let reservationJson = {
      reservationName: this.state.reservationName,
      reservationSize:this.state.reservationSize, 
      reservationPhone:this.state.reservationPhone,
      selectedData:this.state.selectedData,
      reservationNote:this.state.reservationNote,
      status: 'active',
    }

    fetch(`${serverUrl}/orders/reservation`,{
        method: 'POST',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reservationJson)   
        })

    .then((response) => response.json())
    .then((res) => {
      //this.toastNotify('here');
      if(res.success === true){

       //console.log(res.message);

       this.setState({
        showNewReservationModal: this.state.showNewReservationModal?false:true,
        reservationName: '',
        reservationSize: '',
        reservationPhone: '',
        reservationNote:'',
      })

      this.loadReservation();
      

      }else{
        //console.log(res.message);
      }

    })
    .catch((err)=>{
      //console.log(err);
    })

  }



  handleNewWaitlistAction = async ()=>{

    if(this.state.waitlistName.trim() == '' || this.state.waitlistSize.trim() == '' || this.state.waitlistPhone.trim() == ''){
      alert('Please enter all required waitlist information!')
      return;
    }

    let waitListJson = {
      waitlistName: this.state.waitlistName,
      waitlistSize:this.state.waitlistSize, 
      waitlistPhone:this.state.waitlistPhone,
      waitlistSelectedData:this.state.waitlistSelectedData,
      waitlistNote:this.state.waitlistNote,
      status: 'active',
    };

    let localWaitlist = await localStorage.getItem('waitlist');

    let tmpData = '';

    if(localWaitlist == null){
      tmpData = JSON.stringify([JSON.stringify(waitListJson)]);
    }else{

      let JSONWaitlist = JSON.parse(localWaitlist);

      let StringData = JSON.stringify(waitListJson);

      JSONWaitlist.push(StringData);

      tmpData = JSON.stringify(JSONWaitlist);
    }

    await localStorage.setItem('waitlist', tmpData);


    this.setState({
      showNewWaitlistModal: this.state.showNewWaitlistModal?false:true,
      waitlistName: '',
      waitlistSize: '',
      waitlistPhone: '',
      waitlistNote:'',
    })

  }

  toggleReservationModal = ()=>{
    this.setState({showReservations: this.state.showReservations?false:true})
  }

  toggleEditReservationModal = ()=>{
    this.setState({showEditReservationModal: this.state.showEditReservationModal?false:true})
  }

  toggleWaitlistModal = async ()=>{
    this.setState({
      showWaitlist: this.state.showWaitlist?false:true
    })
  }

  toggleEditWaitlistModal = ()=>{
    this.setState({showEditWaitlistModal: this.state.showEditWaitlistModal?false:true})
  }


  EditReservation = (reservation)=>{
    this.toggleEditReservationModal();

    this.setState({
      editReservationId: reservation.ID,
      editReservationName: reservation.name,
      editReservationSize: reservation.size,
      editReservationPhone: reservation.phone,
      editReservationNote: reservation.note,
      editSelectedData: new Date(reservation.time),
      editReservationStatus: reservation.status,
    })
  }


  EditWaitlist = (waitlist)=>{
    this.toggleEditWaitlistModal();

    this.setState({
      editWaitlistName: waitlist.waitlistName,
      editWaitlistSize: waitlist.waitlistSize,
      editWaitlistPhone: waitlist.waitlistPhone,
      editWaitlistNote: waitlist.waitlistNote,
      editWaitlistSelectedData: new Date(waitlist.waitlistSelectedData),
      editWaitlistStatus: waitlist.status,
    })

  }

  updateWaitlist = async () =>{

    if(this.state.editWaitlistName.trim() == '' || this.state.editWaitlistSize.trim() == '' || this.state.editWaitlistPhone.trim() == ''){
      alert('Please enter all required waitlist information!')
      return;
    }

    let waitListJson = {
      waitlistName: this.state.editWaitlistName,
      waitlistSize:this.state.editWaitlistSize, 
      waitlistPhone:this.state.editWaitlistPhone,
      waitlistSelectedData:this.state.editWaitlistSelectedData,
      waitlistNote:this.state.editWaitlistNote,
      status: this.state.editWaitlistStatus,
    };

    let localWaitlist = await localStorage.getItem('waitlist');

    let tmpData = [];

    let JSONWaitlist = JSON.parse(localWaitlist);

    //console.log(JSONWaitlist);


    for(let i=0; i<JSONWaitlist.length; i++){
      let currentJSONWaitlist = JSON.parse(JSONWaitlist[i]);

      if(new Date(currentJSONWaitlist.waitlistSelectedData).getTime() == this.state.editWaitlistSelectedData.getTime()){
        tmpData.push(JSON.stringify(waitListJson));
      }else{
        tmpData.push(JSONWaitlist[i]);
      }

    }
     await localStorage.setItem('waitlist', JSON.stringify(tmpData));

     this.toggleEditWaitlistModal();

  }

  updateReservation = ()=>{

    let dataJson = {
      ID: this.state.editReservationId,
      name: this.state.editReservationName,
      size: this.state.editReservationSize,
      phone: this.state.editReservationPhone,
      note: this.state.editReservationNote,
      time: this.state.editSelectedData,
      status: this.state.editReservationStatus,
    }


    fetch(`${serverUrl}/orders/editreservation`,{
        method: 'POST',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataJson)   
        })

    .then((response) => response.json())
    .then((res) => {
      //this.toastNotify('here');
      if(res.success === true){

      this.loadReservation();

      this.toggleEditReservationModal();

      }else{
        //console.log(res.message);
      this.toggleEditReservationModal();

      }

    })
    .catch((err)=>{
      //console.log(err);
    })



  }



  render() {

        const tables_A = ['A1','A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8'];
        const tables_B = ['B1','B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9'];
        const tables_C = ['C1', 'C2', 'C3', 'C4', 'C5', 'C6'];
        // const tables_R = ['R1', 'R2'];
        const tables_D = ['D1', 'D2'];
        const tables_Bar = ['Bar1', 'Bar2', 'Bar3', 'Bar4', 'Bar5', 'Bar6', 'Bar7', 'Bar8', ];
        const tables_O = ['O1', 'O2', 'O3', 'O4', 'O5', 'O6', 'O7', 'O8'];

        const table_status = ['active', 'void', 'complete'];

        return (
          <Container fluid>
            {/* <Button variant="light" style={{position: 'fixed', bottom: '5px', left:'10px'}} >Active Table</Button>
            <Button variant="secondary" style={{position: 'fixed', bottom: '5px', left:'130px'}} >Void Table</Button>
            <Button variant="success" style={{position: 'fixed', bottom: '5px', left:'237px'}} >Complete Table</Button> */}
            
              {this.state.reservationList.length>0?<Row style={{margin:5}}><Button variant="warning" size="sm" onClick={()=>{this.toggleReservationModal()}}>Upcoming Reservations</Button></Row>:null}
              {(localStorage.getItem('waitlist')!=null && localStorage.getItem('waitlist').length>0)?<Row style={{margin:5}}><Button variant="danger" size="sm" onClick={()=>{this.toggleWaitlistModal()}}>Waitlist</Button></Row>:null}
            <Row className="justify-content-md-center">
               {this.generateSectionData()}
            </Row>

            <Button variant="primary" onClick = {()=>{this.setState({showActivateTableOnly: this.state.showActivateTableOnly?false:true})}} style={{position: 'fixed', bottom: '5px', right:'793px'}}>{this.state.showActivateTableOnly?'Show All Tables':'Show Active Tables'}</Button>
            <Button variant="warning" onClick = {()=>this.handleNewAppointmentModel()} style={{position: 'fixed', bottom: '5px', right:'635px'}}>New Reservation</Button>
            <Button variant="danger" onClick = {()=>this.handleNewWaitlistModel()} style={{position: 'fixed', bottom: '5px', right:'500px'}}>New Waitlist</Button>
            <Button variant="success" onClick = {()=>this.completeAllActive()} style={{position: 'fixed', bottom: '5px', right:'210px'}}>Mark All Active Tables As Complete</Button>
            <Button variant="info" onClick = {()=>this.addSection()} style={{position: 'fixed', bottom: '5px', right:'80px'}}>Add Sections</Button>
            <Button variant="dark" onClick = {()=>this.resetApp()} style={{position: 'fixed', bottom: '5px', right:'5px'}}>Reset</Button>

            <Modal show={this.state.show}>
              <Modal.Header>
                <Modal.Title>How many sections for today?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="d-grid gap-2">
                    <Button variant="outline-success" size="lg" onClick = {()=>this.setSection(1)}>1</Button>
                    <Button variant="outline-success" size="lg" onClick = {()=>this.setSection(2)}>2</Button>
                    <Button variant="outline-success" size="lg" onClick = {()=>this.setSection(3)}>3</Button>
                    <Button variant="outline-success" size="lg" onClick = {()=>this.setSection(4)}>4</Button>
                    <Button variant="outline-success" size="lg" onClick = {()=>this.setSection(5)}>5</Button>
                    <Button variant="outline-success" size="lg" onClick = {()=>this.setSection(6)}>6</Button>
                  </div>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>


            <Modal show={this.state.editShow}>
              <Modal.Header>
                <Modal.Title>Edit Table Information: Section {this.state.selectedSection}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                  <Col>Time: {this.state.tableTime}</Col>
                  <InputGroup className="mb-3">
                    <SplitButton
                      variant="outline-secondary"
                      title={this.state.selectedTableEdit}
                      id="segmented-button-dropdown-1"
                      show={this.state.showDropList}
                      onClick={()=>{this.setState({showDropList: this.state.showDropList?false:true})}}
                    >
                    
                      {tables_A.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({selectedTableEdit: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {tables_B.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({selectedTableEdit: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {tables_C.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({selectedTableEdit: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {tables_D.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({selectedTableEdit: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {tables_Bar.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({selectedTableEdit: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {/* {tables_R.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({selectedTableEdit: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider /> */}
                      {tables_O.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({selectedTableEdit: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                    
                    </SplitButton>

                   <Form.Control aria-label="table people count" placeholder="How many people?" value={this.state.peopleEditEnter} onChange={(e)=>this.setState({peopleEditEnter: e.target.value})} type="number"/>
                   <InputGroup.Text>People</InputGroup.Text>
                   <SplitButton
                      variant="outline-secondary"
                      title={this.state.tableStatus}
                      id="segmented-button-dropdown-2"
                      show={this.state.showStatusDropList}
                      onClick={()=>{this.setState({showStatusDropList: this.state.showStatusDropList?false:true})}}
                    >
                    
                      {table_status.map((status) =>{return <Dropdown.Item onClick={()=>this.setState({tableStatus: status, showStatusDropList: this.state.showStatusDropList?false:true})}>{status}</Dropdown.Item>})}
                      
                    </SplitButton>
                  </InputGroup>
                  </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>this.handleEditModel('')}>Close</Button>
                <Button variant="primary" onClick={()=>this.editRecordAction()}>Save</Button>
              </Modal.Footer>
            </Modal>


            <Modal show={this.state.addShow}>
              <Modal.Header>
                <Modal.Title>Assign People to Section: {this.state.curSection}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Col>
                  <InputGroup className="mb-3">
                    <SplitButton
                      variant="outline-secondary"
                      title={this.state.tableOption}
                      id="segmented-button-dropdown-1"
                      show={this.state.showDropList}
                      onClick={()=>{this.setState({showDropList: this.state.showDropList?false:true})}}
                    >
                    
                      {tables_A.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({tableOption: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {tables_B.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({tableOption: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {tables_C.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({tableOption: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {tables_D.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({tableOption: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {tables_Bar.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({tableOption: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider />
                      {/* {tables_R.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({tableOption: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                      <Dropdown.Divider /> */}
                      {tables_O.map((table) =>{return <Dropdown.Item onClick={()=>this.setState({tableOption: table, showDropList: this.state.showDropList?false:true})}>{table}</Dropdown.Item>})}
                    
                    </SplitButton>

                   <Form.Control aria-label="table people count" placeholder="How many people?" value={this.state.peopleEnter} onChange={(e)=>this.setState({peopleEnter: e.target.value})} type="number"/>
                   <InputGroup.Text>People</InputGroup.Text>
                  </InputGroup>
                  </Col>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>this.handleAddModel('')}>Close</Button>
                <Button variant="primary" onClick={()=>this.addRecord()}>Save</Button>
              </Modal.Footer>
            </Modal>


            {/* <Modal show={this.state.showEditModal}>
              <Modal.Header>
                <Modal.Title>Change Table Status: </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="d-grid gap-2">
                    <Button variant="light" size="lg" onClick = {()=>this.editRecordAction('active')}>Active Table</Button>
                    <Button variant="secondary" size="lg" onClick = {()=>this.editRecordAction('void')}>Void Table</Button>
                    <Button variant="success" size="lg" onClick = {()=>this.editRecordAction('complete')}>Complete Table</Button>
                  </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="default" onClick={()=>this.editRecord('','')}>Cancel</Button>
              </Modal.Footer>
            </Modal> */}


            <Modal show={this.state.showNewReservationModal}>
              <Modal.Header>
                <Modal.Title>Enter Reservation Info: </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              
                <Col className="text-center">
                  <DatePicker 
                    wrapperClassName="datepicker"
                    selected={this.state.selectedData}
                    onChange={(date) => this.setState({selectedData: date})}
                    minDate={this.state.startDate}
                    maxDate={addDays(this.state.startDate, 30)}
                    placeholderText="Select a date between today and 30 days in the future"
                    showTimeSelect
                    timeIntervals={15}
                    filterTime={(time)=>{
                      const selectedDate = new Date(time);
                      if(selectedDate.getDate() == new Date().getDate()){
                        return (selectedDate > new Date()) && selectedDate.getHours() >= 11 && selectedDate.getHours() <= 22;
                      }else{
                        return selectedDate.getHours() >= 11 && selectedDate.getHours() <= 22;
                      }
                      }}
                    dateFormat="MMMM d, yyyy hh:mm aa"
                    inline
                  />
                </Col>

                <Col>
                  <InputGroup className="mb-3">
                   <InputGroup.Text>Date & Time</InputGroup.Text>
                   <Form.Control value={format(this.state.selectedData, "MM/dd/yyyy, hh:mm aa")} disabled/>
                   </InputGroup>


                  <InputGroup className="mb-3">
                   <InputGroup.Text>Name</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Name" value={this.state.reservationName} onChange={(e)=>this.setState({reservationName: e.target.value})}/>
                   </InputGroup>

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Group Size</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="How many people" value={this.state.reservationSize} onChange={(e)=>this.setState({reservationSize: e.target.value})} type="number"/>
                   </InputGroup>       

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Phone Number</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Phone number" value={this.state.reservationPhone} onChange={(e)=>this.setState({reservationPhone: e.target.value})} type="number"/>
                   </InputGroup>    

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Note</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Note" value={this.state.reservationNote} onChange={(e)=>this.setState({reservationNote: e.target.value})} type="text"/>
                   </InputGroup>             
                </Col>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>this.handleNewAppointmentModel()}>Cancel</Button>
                <Button variant="primary" onClick={()=>this.handleNewAppointmentAction()}>Save</Button>
              </Modal.Footer>
            </Modal>


            <Modal show={this.state.showNewWaitlistModal}>
              <Modal.Header>
                <Modal.Title>Enter Waitlist Info: </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Col>
                  <InputGroup className="mb-3">
                   <InputGroup.Text>Date & Time</InputGroup.Text>
                   <Form.Control value={format(this.state.waitlistSelectedData, "MM/dd/yyyy, hh:mm aa")} disabled/>
                   </InputGroup>


                  <InputGroup className="mb-3">
                   <InputGroup.Text>Name</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Name" value={this.state.waitlistName} onChange={(e)=>this.setState({waitlistName: e.target.value})}/>
                   </InputGroup>

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Group Size</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="How many people" value={this.state.waitlistSize} onChange={(e)=>this.setState({waitlistSize: e.target.value})} type="number"/>
                   </InputGroup>       

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Phone Number</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Phone number" value={this.state.waitlistPhone} onChange={(e)=>this.setState({waitlistPhone: e.target.value})} type="number"/>
                   </InputGroup>    

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Note</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Note" value={this.state.waitlistNote} onChange={(e)=>this.setState({waitlistNote: e.target.value})} type="text"/>
                   </InputGroup>             
                </Col>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>this.handleNewWaitlistModel()}>Cancel</Button>
                <Button variant="primary" onClick={()=>this.handleNewWaitlistAction()}>Save</Button>
              </Modal.Footer>
            </Modal>


            <Modal show={this.state.showReservations}>
              <Modal.Header>
                <Modal.Title>Reservation List(Today & Tomorrow): </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{maxHeight: '400px', overflow: 'auto'}}>
                <ListGroup>
                  {
                  this.state.reservationList.map((reservation)=>{
                    if(this.state.showActivateTableOnly){
                      if(reservation.status == 'active' || reservation.status == 'noticed'){
                        return <Row><Col xs={10}><ListGroup.Item style={{backgroundColor:reservation.status == 'void'?'gray':reservation.status == 'complete'?'#b3e6b3':'white'}}>
                            {format(new Date(reservation.time), "MM/dd/yyyy, hh:mm aa")}<br/>
                            Name:{reservation.name}{', Phone:'}{reservation.phone}{', '}{reservation.size} People, Note:{reservation.note}
                          </ListGroup.Item></Col><Col xs={1} style={{minHeight: '65px'}} onClick={()=>this.EditReservation(reservation)} className="d-flex flex-column justify-content-center align-items-center">✏️</Col>{(reservation.status=='active'||reservation.status=='noticed')?<Col xs={1} style={{minHeight: '65px'}} className="d-flex flex-column justify-content-center align-items-center" onClick={()=>reservation.status == 'noticed'?alert('Customer has already been noticed!'):this.messageReservation(reservation.ID, reservation.name, reservation.phone, reservation.size)}>{reservation.status == 'noticed'?'✅':'💬'}</Col>:null}</Row>;
                      }
                    }else{
                      return <Row><Col xs={10}><ListGroup.Item style={{backgroundColor:reservation.status == 'void'?'gray':reservation.status == 'complete'?'#b3e6b3':'white'}}>
                            {format(new Date(reservation.time), "MM/dd/yyyy, hh:mm aa")}<br/>
                            Name:{reservation.name}{', Phone:'}{reservation.phone}{', '}{reservation.size} People, Note:{reservation.note}
                          </ListGroup.Item></Col><Col xs={1} style={{minHeight: '65px'}} onClick={()=>this.EditReservation(reservation)} className="d-flex flex-column justify-content-center align-items-center">✏️</Col>{(reservation.status=='active'||reservation.status=='noticed')?<Col xs={1} style={{minHeight: '65px'}} className="d-flex flex-column justify-content-center align-items-center" onClick={()=>reservation.status == 'noticed'?alert('Customer has already been noticed!'):this.messageReservation(reservation.ID, reservation.name, reservation.phone, reservation.size)}>{reservation.status == 'noticed'?'✅':'💬'}</Col>:null}</Row>;
                      }
                    })
                    }

                </ListGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>this.toggleReservationModal()}>Close</Button>
              </Modal.Footer>
            </Modal>


            <Modal show={this.state.showWaitlist}>
              <Modal.Header>
                <Modal.Title>Waitlist: </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{maxHeight: '400px', overflow: 'auto'}}>
                <ListGroup>
                  {
                    //["{\"waitlistName\":\"dasd\",\"waitlistSize\":\"12\",\"waitlistPhone\":\"1213123123\",\"waitlistSelectedData\":\"2023-09-13T02:59:09.571Z\",\"waitlistNote\":\"\",\"status\":\"active\"}"]
                  (
                    localStorage.getItem('waitlist')!=null && localStorage.getItem('waitlist').length>0)?JSON.parse(localStorage.getItem('waitlist')).map((reservation)=>{
                    reservation = JSON.parse(reservation);
                    if(this.state.showActivateTableOnly){
                      if(reservation.status == 'active' || reservation.status == 'noticed'){
                        return <Row><Col xs={10}><ListGroup.Item style={{backgroundColor:reservation.status == 'void'?'gray':reservation.status == 'complete'?'#b3e6b3':'white'}}>
                            {format(new Date(reservation.waitlistSelectedData), "MM/dd/yyyy, hh:mm aa")}<br/>
                            Name:{reservation.waitlistName}{', Phone:'}{reservation.waitlistPhone}{', '}{reservation.waitlistSize} People, Note:{reservation.waitlistNote}
                          </ListGroup.Item></Col><Col xs={1} style={{minHeight: '65px'}} onClick={()=>this.EditWaitlist(reservation)} className="d-flex flex-column justify-content-center align-items-center">✏️</Col>{(reservation.status=='active'||reservation.status=='noticed')?<Col xs={1} style={{minHeight: '65px'}} className="d-flex flex-column justify-content-center align-items-center" onClick={()=>reservation.status == 'noticed'?alert('Customer has already been noticed!'):this.messageWaitlist(reservation.waitlistSelectedData, reservation.waitlistName, reservation.waitlistPhone, reservation.waitlistSize)}>{reservation.status == 'noticed'?'✅':'💬'}</Col>:null}</Row>;
                      }
                    }else{
                      return <Row><Col xs={10}><ListGroup.Item style={{backgroundColor:reservation.status == 'void'?'gray':reservation.status == 'complete'?'#b3e6b3':'white'}}>
                            {format(new Date(reservation.waitlistSelectedData), "MM/dd/yyyy, hh:mm aa")}<br/>
                            Name:{reservation.waitlistName}{', Phone:'}{reservation.waitlistPhone}{', '}{reservation.waitlistSize} People, Note:{reservation.waitlistNote}
                          </ListGroup.Item></Col><Col xs={1} style={{minHeight: '65px'}} onClick={()=>this.EditWaitlist(reservation)} className="d-flex flex-column justify-content-center align-items-center">✏️</Col>{(reservation.status=='active'||reservation.status=='noticed')?<Col xs={1} style={{minHeight: '65px'}} className="d-flex flex-column justify-content-center align-items-center" onClick={()=>reservation.status == 'noticed'?alert('Customer has already been noticed!'):this.messageWaitlist(reservation.waitlistSelectedData, reservation.waitlistName, reservation.waitlistPhone, reservation.waitlistSize)}>{reservation.status == 'noticed'?'✅':'💬'}</Col>:null}</Row>;
                      }
                    }):null
                    }

                </ListGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>this.toggleWaitlistModal()}>Close</Button>
              </Modal.Footer>
            </Modal>



            <Modal show={this.state.showEditReservationModal}>
              <Modal.Header>
                <Modal.Title>Update Reservation Info: </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              
                <Col className="text-center">
                  <DatePicker 
                    wrapperClassName="datepicker"
                    selected={this.state.editSelectedData}
                    onChange={(date) => this.setState({editSelectedData: date})}
                    minDate={this.state.startDate}
                    maxDate={addDays(this.state.startDate, 30)}
                    placeholderText="Select a date between today and 30 days in the future"
                    showTimeSelect
                    timeIntervals={15}
                    filterTime={(time)=>{
                      const selectedDate = new Date(time);
                      if(selectedDate.getDate() == new Date().getDate()){
                        return (selectedDate > new Date()) && selectedDate.getHours() >= 11 && selectedDate.getHours() <= 22;
                      }else{
                        return selectedDate.getHours() >= 11 && selectedDate.getHours() <= 22;
                      }
                      }}
                    dateFormat="MMMM d, yyyy hh:mm aa"
                    inline
                  />
                </Col>

                <Col>
                  <InputGroup className="mb-3">
                   <InputGroup.Text>Date & Time</InputGroup.Text>
                   <Form.Control value={format(this.state.editSelectedData, "MM/dd/yyyy, hh:mm aa")} disabled/>
                   </InputGroup>

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Status</InputGroup.Text>
                   <SplitButton
                      variant="outline-secondary"
                      title={this.state.editReservationStatus}
                      id="segmented-button-dropdown-3"
                      show={this.state.showReservationStatusDropList}
                      onClick={()=>{this.setState({showReservationStatusDropList: this.state.showReservationStatusDropList?false:true})}}
                    >
                    
                      {table_status.map((status) =>{return <Dropdown.Item onClick={()=>this.setState({editReservationStatus: status, showReservationStatusDropList: this.state.showReservationStatusDropList?false:true})}>{status}</Dropdown.Item>})}
                      
                    </SplitButton>
                    </InputGroup>


                  <InputGroup className="mb-3">
                   <InputGroup.Text>Name</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Name" value={this.state.editReservationName} onChange={(e)=>this.setState({editReservationName: e.target.value})}/>
                   </InputGroup>

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Group Size</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="How many people" value={this.state.editReservationSize} onChange={(e)=>this.setState({editReservationSize: e.target.value})} type="number"/>
                   </InputGroup>       

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Phone Number</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Phone number" value={this.state.editReservationPhone} onChange={(e)=>this.setState({editReservationPhone: e.target.value})} type="number"/>
                   </InputGroup>    

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Note</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Note" value={this.state.editReservationNote} onChange={(e)=>this.setState({editReservationNote: e.target.value})} type="text"/>
                   </InputGroup>             
                </Col>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>this.toggleEditReservationModal()}>Cancel</Button>
                <Button variant="primary" onClick={()=>this.updateReservation()}>Update</Button>
              </Modal.Footer>
            </Modal>


            <Modal show={this.state.showEditWaitlistModal}>
              <Modal.Header>
                <Modal.Title>Update Waitlist Info: </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Col>
                  <InputGroup className="mb-3">
                   <InputGroup.Text>Date & Time</InputGroup.Text>
                   <Form.Control value={format(this.state.editWaitlistSelectedData, "MM/dd/yyyy, hh:mm aa")} disabled/>
                   </InputGroup>

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Status</InputGroup.Text>
                   <SplitButton
                      variant="outline-secondary"
                      title={this.state.editWaitlistStatus}
                      id="segmented-button-dropdown-3"
                      show={this.state.showWaitlistStatusDropList}
                      onClick={()=>{this.setState({showWaitlistStatusDropList: this.state.showWaitlistStatusDropList?false:true})}}
                    >
                    
                      {table_status.map((status) =>{return <Dropdown.Item onClick={()=>this.setState({editWaitlistStatus: status, showWaitlistStatusDropList: this.state.showWaitlistStatusDropList?false:true})}>{status}</Dropdown.Item>})}
                      
                    </SplitButton>
                    </InputGroup>

                  <InputGroup className="mb-3">
                   <InputGroup.Text>Name</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Name" value={this.state.editWaitlistName} onChange={(e)=>this.setState({editWaitlistName: e.target.value})}/>
                   </InputGroup>

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Group Size</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="How many people" value={this.state.editWaitlistSize} onChange={(e)=>this.setState({editWaitlistSize: e.target.value})} type="number"/>
                   </InputGroup>       

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Phone Number</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Phone number" value={this.state.editWaitlistPhone} onChange={(e)=>this.setState({editWaitlistPhone: e.target.value})} type="number"/>
                   </InputGroup>    

                   <InputGroup className="mb-3">
                   <InputGroup.Text>Note</InputGroup.Text>
                   <Form.Control aria-label="table people count" placeholder="Note" value={this.state.editWaitlistNote} onChange={(e)=>this.setState({editWaitlistNote: e.target.value})} type="text"/>
                   </InputGroup>             
                </Col>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>this.toggleEditWaitlistModal()}>Cancel</Button>
                <Button variant="primary" onClick={()=>this.updateWaitlist()}>Update</Button>
              </Modal.Footer>
            </Modal>


          </Container>
      );
  }
}

export default App;
